.App {
  text-align: center;
  background-color: #ff00ff; /* Knalroze achtergrond */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Witte tekst */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.App-logo {
  width: 200px;
  height: auto;
  border-radius: 10px;
}

.App-link {
  color: #ffffcc; /* Geelachtige link voor contrast */
  font-weight: bold;
}
